function recaptchaCallback() {
    $('#f_recaptcha').valid();
}
$(document).ready(function(){

	// WINDOW SIZE

	// call this function to get windowSize any time
	var windowHeight;
	var windowWidth;

	function windowSize() {
	  windowHeight = window.innerHeight ? window.innerHeight : $(window).height();
	  windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
	}

	// ANCRES DOUCES

	function juizScrollTo(element){
		$(element).click(function(){
			var goscroll = false;
			var the_hash = $(this).attr("href");
			var regex = new RegExp("\#(.*)","gi");
			var the_element = '';

			if(the_hash.match("\#(.+)")) {
				the_hash = the_hash.replace(regex,"$1");

				if($("#"+the_hash).length>0) {
					the_element = "#" + the_hash;
					goscroll = true;
				}
				else if($("a[name=" + the_hash + "]").length>0) {
					the_element = "a[name=" + the_hash + "]";
					goscroll = true;
				}

				if(goscroll) {
					$('html, body').animate({
						scrollTop:$(the_element).offset().top - 180
					}, 'slow');
					return false;
				}
			}
		});
	};
	juizScrollTo('a[href^="#"]');

	// GRID ON MENU

	$('.itemHeader').each(function(){
		if($(this).hasClass('anchor1')){
			$(this).addClass('offset-1');
		}
	});

	/* VIDEO BG */

    var timeoutId;

    function videobgEnlarge() {

	    var videoBgAspect = $(".videobg-aspect");
	    var videoBgWidth = $(".videobg-width");
	    var videoAspect = videoBgAspect.outerHeight() / videoBgAspect.outerWidth();
	    var windowAspect = ($(window).height() / $(window).width());

	    if (windowAspect > videoAspect) {
	        videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
	    } else {
	        videoBgWidth.width(100 + "%")
	    }

    }

    $(window).resize(function() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(videobgEnlarge, 100);
    });

    $(function() {
      videobgEnlarge();
    });

    /* VIDEO BG */

    var timeoutId2;

    function videobgEnlarge2() {

	    var videoBgAspect = $(".videobg-aspect2");
	    var videoBgWidth = $(".videobg-width2");
	    var videoAspect = videoBgAspect.outerHeight() / videoBgAspect.outerWidth();
	    var windowAspect = ($(window).height() / $(window).width());

	    if (windowAspect > videoAspect) {
	        videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
	    } else {
	        videoBgWidth.width(100 + "%")
	    }

    }

    $(window).resize(function() {
      clearTimeout(timeoutId2);
      timeoutId = setTimeout(videobgEnlarge2, 100);
    });

    $(function() {
      videobgEnlarge2();
    });

        /* VIDEO BG */

        var timeoutId3;

        function videobgEnlarge3() {

    	    var videoBgAspect = $(".videobg-aspect3");
    	    var videoBgWidth = $(".videobg-width3");
    	    var videoAspect = videoBgAspect.outerHeight() / videoBgAspect.outerWidth();
    	    var windowAspect = ($(window).height() / $(window).width());

    	    if (windowAspect > videoAspect) {
    	        videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
    	    } else {
    	        videoBgWidth.width(100 + "%")
    	    }

        }

        $(window).resize(function() {
          clearTimeout(timeoutId3);
          timeoutId = setTimeout(videobgEnlarge3, 100);
        });

        $(function() {
          videobgEnlarge3();
        });


	// CAPTCHA CHAISPASQUOI

    setTimeout(function () {
      if ($('#contact_form').length) {
          $('#contact_rgpd').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
    }, 100);        


    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#contact_rgpd').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#contact_form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.checkboxAlert');
                error.addClass("checkbox");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });


    // PARALLAX

    function parallax() {

          // Calculer le scroll
          var windowTop = $(window).scrollTop();
          var windowHeight = $(window).height();
          var midWindow = windowTop + (windowHeight / 2);
      
       $('.parallax').each(function(){

          // Calculer le milieu de la div
          var topDiv = $(this).offset().top;
          var HeightDiv = $(this).outerHeight(true);
          var midDiv = topDiv + (HeightDiv / 2);
         
          var parallaxOne = (midDiv - midWindow) / 3.8;
          var parallaxTwo = (midDiv - midWindow) / 4.2 ;
          var parallaxThree = (midDiv - midWindow) / 5;      
          var parallaxFour = (midDiv - midWindow) / 2.5;      
          var parallaxFive = (midDiv - midWindow) / 2.7;      
         
          if($(this).hasClass('one')){
            // console.log('midWindow : ' + midWindow);
            // console.log('midDiv : ' + midDiv);
            // console.log('parallaxOne : ' + parallaxOne);
            $(this).css('transform', 'translateY(' + parallaxOne + 'px)');
          } else if($(this).hasClass('two')){
           $(this).css('transform', 'translateY(' + parallaxTwo + 'px)');
          } else if($(this).hasClass('three')){
           $(this).css('transform', 'translateY(' + parallaxThree + 'px)');
          } else if($(this).hasClass('four')){
           $(this).css('transform', 'translateY(' + parallaxFour + 'px)');
          } else if($(this).hasClass('five')){
           $(this).css('transform', 'translateY(' + parallaxFive + 'px)');
          } 
       });  
    }

    parallax();
    $( window ).resize(parallax);
    $( window ).scroll(parallax);


	function mylazyload () {
   
        // Bas de la fenetre (en pixels)
        var windowBottom = $(window).scrollTop() + $(window).height();

        // Afficher les images et le texte quand on scroll
        $('.mylazyload').each(function(){

            // Le haut de mon Ã©lÃ©ment (y)
            var myTop = $(this).offset().top;  

            var translate = parseInt($(this).css('transform').split(',')[5]);
          
            // DÃ¨s que l'Ã©lÃ©ment devient visible dans la fenetre
            if (myTop < (windowBottom + translate - 100)) {
               
                $(this).addClass('mylazyloaded');

                // On affiche l'image (en lazyload)
                $(this).find("img").each(function(){
                    $(this).attr("src", $(this).data('src'));
                    $(this).removeAttr('data-src');
                });

            } else {
                // L'Ã©lÃ©ment n'est pas dans la fenetre
            }

        });
    }

    mylazyload();
    $( window ).scroll(mylazyload);


   function footerReveal(){
		var footerHeight = $('footer').outerHeight(true);
		var windowHeight = $( window ).height();
		$('.footerRevealContainer').css('margin-bottom', footerHeight);

		if(windowHeight < footerHeight){
			$('.footerRevealContainer').addClass('footerStop');
			$('footer').addClass('footerStop');
		} else{
			$('.footerRevealContainer').removeClass('footerStop');
			$('footer').removeClass('footerStop');
		}
    }

    footerReveal();
    $( window ).resize(footerReveal);


    function cancelParallax(){
    	windowSize();

	    if(windowWidth > 991){
	    	$('.cancel').addClass('parallax');
	    	$('.cancel').css({
	    		'margin-bottom': '0'
	    	});
		} else {
			$('.cancel').removeClass('parallax');
			$('.cancel').css({
				'transform': 'translateY(0)',
				'margin-bottom': '50px'
			});
		}
	}

	$(window).resize(cancelParallax);
	cancelParallax();

	$(document).click(function(e){
	    var container = $('.headerMobile');
	    var container2 = $('.toggleMenu');
    	    // if the target of the click isn't the container nor a descendant of the container
	    if ((!container.is(e.target) && container.has(e.target).length === 0) && (!container2.is(e.target) && container2.has(e.target).length === 0)){
	        $('.toggleMenu').removeClass('active');
			$('.headerMobile').removeClass('active');
	    }
	});

	$('.toggleMenu').click(function(){
		$('.toggleMenu').toggleClass('active');
		$('.headerMobile').toggleClass('active');
	});

	$('.itemHeader').click(function(){
		$('.toggleMenu').removeClass('active');
		$('.headerMobile').removeClass('active');
	});

	var owl = $('.owl-carousel');
	
	owl.owlCarousel({
	    loop: true,
	    nav: false,
	    dots: false,
	    autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
	    responsiveClass:true,
	    responsive:{
	        0:{
	            items:1
	        },
	        767:{
	            items:3
	        },
	        1050:{
	            items:5
	        }
	    }
	});

	// Go to the next item
	$('.customPrevBtn').click(function() {
	    owl.trigger('prev.owl.carousel');
	})
	// Go to the previous item
	$('.customNextBtn').click(function() {
	    owl.trigger('next.owl.carousel');
	})
	

	$('.city').click(function(){
		if(windowWidth > 1200){

		  	var city = $(this).data("city");
		  	var cityClass = '.' + city;

		  	$('.city').removeClass('active');
		  	$(this).addClass('active');
		  	$('.adress').removeClass('active');                   
		  	$('.adresses').find(cityClass).addClass('active');
		  }
	});

	function widthMap(){
		windowSize();

	    if(windowWidth < 1200){
	    	var mapVectorHeight = $('.svgContainer').outerHeight();
			$('.cities').css({
				height : mapVectorHeight
			});
			$('.map').removeClass('parallax one');
			$('.map').css('transform', 'translateY(0px)');
		} else {
			$('.cities').css({
				height : 'inherit',
			});
			$('.map').addClass('parallax one');

		}
	}

	widthMap();
	$( window ).resize(widthMap);


	$('.adress').click(function(){
		windowSize();

 		if(windowWidth < 1200){
			if($(this).hasClass('active')){
				$(this).css('height', '15px');
				$(this).removeClass('active');
			} else {
				var height = 0;
				$(this).children().each(function(){
					height = height + $(this).outerHeight(true); 
					console.log('ahoy');
				})
			
				$('.adress').css('height', '15px');
				$('.adress').removeClass('active');

				$(this).css('height', height);
				$(this).addClass('active');
			}
		} else {
			$('.adress').css('height', 'inherit');
		}
		





	})

	function heightOnAdress(){
			windowSize();

	 		if(windowWidth > 1200){
				$('.adress').css('height', 'inherit');
				$('.adress').removeClass('active');
				$('.city').removeClass('active');
			} else {
				$('.adress').css('height', '15px');
				$('.adress').removeClass('active');
			}
		}

		heightOnAdress();
		$( window ).resize(heightOnAdress);


});